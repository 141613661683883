import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { InPageNavigationServiceV1 } from '@volkswagen-onehub/audi-in-page-navigation-service';
import { useInViewObservers } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
// import { AnchorScrollData, getAnchorOffsets } from '../../utils/get-anchor-offset';
import { ArrowIcon } from './ArrowIcon';
import {
  StyledContainer,
  StyledHeadline,
  StyledNav,
  StyledLink,
  StyledList,
  StyledListItem,
  // StyledLineIndicator,
} from './AnchorNavigationStyles';
import {
  StickyNavContainer,
  StickyNavHeadline,
  StickyNavHeadlineText,
  StickyList,
  StickyNav,
} from './StickyStyles';
// import { useIndicator } from '../../utils/use-indicator';
// import { useDebounce } from '../../utils/use-debounce';
import { WarningMessage } from '../warningmessage/WarningMessage';
import { useInPageNavigationService } from '../../context/InPageNavigationContext';
import { useTrackingManager } from '../../utils/useTrackingManager';
import { AnchorNavigationProps, RegisteredAnchor } from './AnchorNavigationTypes';

const toRegisteredAnchor = (element: HTMLElement): RegisteredAnchor => ({
  element,
  anchor: {
    id: element.id,
    name: String(element.getAttribute('data-anchor-name')),
  },
});

const findRegisteredServiceAnchors = (inPageNavigationService: InPageNavigationServiceV1) =>
  inPageNavigationService
    .getOrderedAnchorsList()
    .map(({ id }) => document.getElementById(id))
    .filter(notEmpty)
    .map(toRegisteredAnchor);

const stickyThreshold = -200;
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const minAnchors = 2;
const maxAnchors = 10;

export const AnchorNavigation: React.FC<AnchorNavigationProps> = ({
  linkTracking,
  navigationTracking,
  headline,
}) => {
  const inPageNavigationService = useInPageNavigationService();
  const [registeredAnchors, setRegisteredAnchors] = React.useState<RegisteredAnchor[]>();
  const [fallbackEnabled, setFallbackEnabled] = React.useState(false);

  const panelRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLUListElement>(null);
  const navigationRef = React.useRef<HTMLDivElement>(null);
  const activeAnchorRef = React.useRef<HTMLAnchorElement>(null);
  const stickyNavHeadlineRef = React.useRef<HTMLDivElement>(null);

  const [isStickyNavOpen, setIsStickyNavOpen] = React.useState(false);
  const [wasStickNavVisibleOnce, setWasStickNavVisibleOnce] = React.useState(false);
  const [isSticky, setIsSticky] = React.useState(false);
  const [inView, setInView] = React.useReducer(() => true, false);

  const [observeInView, disconnectInView] = useInViewObservers(setInView);
  const trackingManager = useTrackingManager();

  const toggleStickyNav = React.useCallback(
    (force?: boolean) => {
      if (force !== undefined) {
        setIsStickyNavOpen(force);
      } else {
        setIsStickyNavOpen(!isStickyNavOpen);
      }
    },
    [isStickyNavOpen],
  );
  const setHeight = (panelHeight: number, stickyBarHeight: number) => {
    panelRef?.current?.style.setProperty('--panel-height', `${panelHeight}px`);
    panelRef?.current?.style.setProperty('--sticky-bar-height', `${stickyBarHeight}px`);
  };
  React.useEffect(() => {
    if (inView) {
      trackingManager.impression();
      disconnectInView();
    }
    return () => {
      disconnectInView();
    };
  }, [inView]);

  const initObservers = React.useCallback((container: HTMLDivElement) => {
    observeInView(container);

    // handles sticky state
    const observer = new IntersectionObserver(
      ([e]) => {
        const isCurrentlyStick = e.boundingClientRect.bottom < -stickyThreshold;
        setIsSticky(isCurrentlyStick);
      },
      {
        rootMargin: `${stickyThreshold}px 0px 0px 0px`,
      },
    );

    observer.observe(container);
  }, []);

  // const [offsetData, setOffsetData] = React.useState<AnchorScrollData[]>();
  const [activeAnchor, setActiveAnchor] = React.useState(
    registeredAnchors?.findIndex(({ anchor: { id } }) => id === window.location.hash) ?? -1,
  );

  // const indicatorReady = useIndicator(navigationRef, activeAnchorRef, [activeAnchor]);

  const handleAnchorFallback = React.useCallback(() => {
    const serviceAnchors = findRegisteredServiceAnchors(inPageNavigationService);
    if (!registeredAnchors && !serviceAnchors) return;

    if (!registeredAnchors || registeredAnchors.length !== serviceAnchors.length) {
      setRegisteredAnchors(serviceAnchors);
    } else {
      const anchorJSON = JSON.stringify(serviceAnchors.map(({ anchor }) => anchor));
      const registeredAnchorJSON = JSON.stringify(registeredAnchors.map(({ anchor }) => anchor));
      if (anchorJSON !== registeredAnchorJSON) {
        setRegisteredAnchors(registeredAnchors);
      }
    }
  }, [registeredAnchors]);

  // const updateActiveState = React.useCallback(() => {
  //   if (offsetData) {
  //     const scrollPaddingTop = parseInt(
  //       window.getComputedStyle(document.documentElement).getPropertyValue('scroll-padding-top'),
  //       10,
  //     );

  //     const currentScroll = document.documentElement.scrollTop;

  //     handleDelayedOffsetChanges();

  //     const scrolledAnchorIndex = offsetData.findIndex((object: AnchorScrollData) => {
  //       return (
  //         currentScroll + scrollPaddingTop >= object.start &&
  //         currentScroll + scrollPaddingTop < object.end
  //       );
  //     });

  //     setActiveAnchor(scrolledAnchorIndex);
  //   }
  // }, [offsetData]);

  // const handleDelayedOffsetChanges = React.useCallback(() => {
  //   if (registeredAnchors) {
  //     const newOffsetData = getAnchorOffsets(registeredAnchors.map(({ element }) => element));

  //     if (JSON.stringify(offsetData) === JSON.stringify(newOffsetData)) return;

  //     setOffsetData(newOffsetData);
  //   }
  // }, [offsetData, registeredAnchors]);

  // const handleResize = React.useCallback(() => {
  //   if (registeredAnchors) {
  //     setOffsetData(getAnchorOffsets(registeredAnchors.map(({ element }) => element)));
  //   }
  // }, [registeredAnchors]);

  React.useEffect(() => {
    const anchors = Array.from(document.querySelectorAll<HTMLElement>('[data-anchor-name]'));
    if (anchors.length > 0) {
      setRegisteredAnchors(anchors.map(toRegisteredAnchor));
    } else {
      setFallbackEnabled(true);
    }
  }, []);

  React.useEffect(() => {
    let mounted = true;

    function handler() {
      if (mounted) {
        window.requestAnimationFrame(handleAnchorFallback);
        window.setTimeout(handler, 250);
      }
    }

    if (fallbackEnabled) {
      window.requestAnimationFrame(handler);
    }
    return () => {
      mounted = false;
    };
  }, [fallbackEnabled, handleAnchorFallback]);

  // React.useEffect(() => {
  //   const debouncedResize = useDebounce(handleResize, 250);
  //   window.addEventListener('resize', debouncedResize);
  //   return () => {
  //     window.removeEventListener('resize', debouncedResize);
  //   };
  // }, [handleResize]);

  // React.useEffect(() => {
  //   if (registeredAnchors) {
  //     setOffsetData(getAnchorOffsets(registeredAnchors.map(({ element }) => element)));
  //   }
  // }, [registeredAnchors]);

  // React.useEffect(() => {
  //   let mounted = true;
  //   function handler() {
  //     if (mounted) {
  //       // updateActiveState();
  //       window.requestAnimationFrame(handler);
  //     }
  //   }
  //   window.requestAnimationFrame(handler);
  //   return () => {
  //     mounted = false;
  //   };
  // }, [updateActiveState]);

  // React.useEffect(() => {
  //   if (navigationRef.current && activeAnchorRef.current) {
  //     const activeAnchorLeft = activeAnchorRef.current.offsetLeft;
  //     const { scrollLeft } = navigationRef.current;
  //     if (scrollLeft > activeAnchorLeft) {
  //       navigationRef.current.scrollBy(activeAnchorLeft - scrollLeft, 0);
  //     } else {
  //       const activeAnchorRight = activeAnchorLeft + activeAnchorRef.current.offsetWidth;
  //       const scrollWidth = navigationRef.current.offsetWidth + scrollLeft;
  //       if (scrollWidth < activeAnchorRight) {
  //         navigationRef.current.scrollBy((scrollWidth - activeAnchorRight) * -1, 0);
  //       }
  //     }
  //   }
  // }, [activeAnchor]);

  React.useEffect(() => {
    // toggle height of sticky nav content
    const height = (isStickyNavOpen && contentRef.current?.scrollHeight) || 0;
    const stickyBarHeight = stickyNavHeadlineRef.current?.offsetHeight || 0;
    window.requestAnimationFrame(() => {
      setHeight(height, stickyBarHeight);
    });
  }, [isStickyNavOpen]);

  React.useEffect(() => {
    // set to true if sticky nav is shown the first time
    if (!wasStickNavVisibleOnce && isSticky) {
      setWasStickNavVisibleOnce(true);
    }
    // hide open menu when scrolling up
    if (!isSticky) {
      setIsStickyNavOpen(false);
    }
  }, [isSticky]);

  if (!registeredAnchors || !headline) {
    return null;
  }

  const handleNavClick = () => {
    toggleStickyNav();
    if (navigationTracking) {
      navigationTracking(isStickyNavOpen);
    }
  };

  const handleContentClick = (name: string, index: number) => {
    if (linkTracking) {
      linkTracking(name, index + 1);
    }
    setActiveAnchor(index);
  };

  const render = () => {
    if (registeredAnchors.length < minAnchors) {
      return null;
    }
    return (
      <>
        <StyledContainer
          data-testid="anchor-navigation"
          ref={initObservers}
          className={isSticky ? 'hidden' : ''}
        >
          <StyledHeadline variant="order2">{headline}</StyledHeadline>
          <StyledNav ref={navigationRef} role="navigation">
            <StyledList role="menubar">
              {registeredAnchors.map(
                ({ anchor: { id, name } }, index) =>
                  index < maxAnchors && (
                    <StyledListItem key={id} role="none">
                      <StyledLink
                        href={`#${id}`}
                        ref={activeAnchor === index ? activeAnchorRef : undefined}
                        role="menuitem"
                        aria-expanded={false}
                        onClick={() => handleContentClick(name, index)}
                      >
                        <Text as="span">
                          <UeElement type="text" property="text" label="Anchor Name">
                            {name}
                          </UeElement>
                        </Text>
                      </StyledLink>
                    </StyledListItem>
                  ),
              )}
            </StyledList>
            {/* {indicatorReady && <StyledLineIndicator />} */}
          </StyledNav>
        </StyledContainer>
        <StickyNavContainer
          className={(wasStickNavVisibleOnce && (isSticky ? 'visible' : 'hidden')) || ''}
          aria-hidden="true"
        >
          <StickyNavHeadline onClick={() => handleNavClick()} ref={stickyNavHeadlineRef}>
            <StickyNavHeadlineText>
              {registeredAnchors[Math.max(0, activeAnchor)].anchor.name}
            </StickyNavHeadlineText>
            <ArrowIcon rotated={isStickyNavOpen} />
          </StickyNavHeadline>
          <StickyNav className={isStickyNavOpen ? 'open' : ''} ref={panelRef}>
            <StickyList ref={contentRef}>
              {registeredAnchors.map(
                ({ anchor: { id, name } }, index) =>
                  index < maxAnchors && (
                    <StyledListItem
                      key={id}
                      aria-label={name}
                      onClick={() => handleContentClick(name, index)}
                    >
                      <StyledLink href={`#${id}`} onClick={() => toggleStickyNav(false)}>
                        <Text as="span">{name}</Text>
                      </StyledLink>
                    </StyledListItem>
                  ),
              )}
            </StickyList>
          </StickyNav>
        </StickyNavContainer>
      </>
    );
  };

  return (
    <>
      <WarningMessage
        anchors={registeredAnchors.length}
        maxAnchors={maxAnchors}
        minAnchors={minAnchors}
      />
      {render()}
    </>
  );
};
